import Analytics from 'analytics'
import originalSrc from 'analytics-plugin-original-source'
import { analyticsTimeOnPage } from './plugins/timeOnPage'

const createAnalytics = ({ application, plugins = [], ...options } = {}) =>
  Analytics({
    app: application,
    plugins: [
      originalSrc(),
      analyticsTimeOnPage(),
      ...plugins
    ],
    ...options
  })

export { createAnalytics }
