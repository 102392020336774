export const EVENT_ORIGIN = {
  CLIENT: 'client',
  SERVER: 'server'
}

export const EVENT_ACTION = {
  APP_EVENT: 'app_event',
  BUTTON_CLICK: 'button_click',
  DROPDOWN_SELECT: 'dropdown_select',
  LINK_CLICK: 'link_click',
  PAGE_VIEW: 'page_view'
}

const eventFields = {}

const addEventField = (name, value) => {
  eventFields[name] = value || name?.toLowerCase()
}

// TODO - Need to refactor this a bit. Sonar didn't like the duplication with eventFields,
//        profileFields, and ulsFields. This is a temporary hack to get around it.
//        There is likely a much better consolidated mapping we can create anyways.
addEventField('ACTUAL_DEBT')
addEventField('AUTH0_EMAIL')
addEventField('AUTH0_EMAIL_HASH')
addEventField('AUTH0_USER_ID')
addEventField('BROWSER_HEIGHT')
addEventField('BROWSER_ID')
addEventField('BROWSER_WIDTH')
addEventField('CAMPAIGN')
addEventField('CEREBRO_PID', 'cerebro_id')
addEventField('CLICK_URL')
addEventField('CLIENT_ID')
addEventField('COAPP_CREDIT_PULL_ATTEMPTS_REMAINING')
addEventField('CONSENT_ID')
addEventField('CONSENT_ID_SOURCE')
addEventField('CONSENT_MEDIA')
addEventField('CONSENT_SCOPE')
addEventField('CONSENT_TEXT')
addEventField('CREDIT_PULL_ATTEMPTS_REMAINING')
addEventField('CREDIT_PULL_ERROR')
addEventField('CREDIT_REPORT_ID')
addEventField('CTA_TEXT')
addEventField('CTA_VALUE')
addEventField('EVENT_DATETIME')
addEventField('EVENT_ID')
addEventField('EVENT_ORIGIN')
addEventField('EVENT_TYPE')
addEventField('FDR_APPLICANT_ID')
addEventField('FDR_ELIGIBLE')
addEventField('FORM_ADDRESS')
addEventField('FORM_CITY')
addEventField('FORM_COAPP_DATE_OF_BIRTH')
addEventField('FORM_COAPP_FIRST_NAME')
addEventField('FORM_COAPP_LAST_NAME')
addEventField('FORM_COUNTRY')
addEventField('FORM_DATE_OF_BIRTH')
addEventField('FORM_DAYS_BEHIND')
addEventField('FORM_EMAIL')
addEventField('FORM_EMAIL_HASH')
addEventField('FORM_ESTIMATED_DEBT_INITIAL')
addEventField('FORM_FIRST_NAME')
addEventField('FORM_IDENTITY_QUESTIONS_ANSWERED')
addEventField('FORM_LAST_NAME')
addEventField('FORM_PAYMENT_DATE')
addEventField('FORM_PHONE')
addEventField('FORM_PHONE_HASH')
addEventField('FORM_SOCIAL_SECURITY')
addEventField('FORM_STATE')
addEventField('FORM_ZIPCODE')
addEventField('GA_CLIENT_ID')
addEventField('GA_SESSION_ID')
addEventField('LEAD_UUID')
addEventField('LENDAGE_ELIGIBLE')
addEventField('META_TITLE')
addEventField('ORIGINAL_LANDING_PAGE')
addEventField('PAGE_NAME')
addEventField('PERSON_ID')
addEventField('PRODUCT_INTERACTION_ID')
addEventField('PROFILE_ID')
addEventField('SESSION_ID')
addEventField('SUIP_ELIGIBLE')
addEventField('URL')
addEventField('URL_HASH')
addEventField('URL_PATH')
addEventField('URL_SEARCH')
addEventField('USER_AGENT')
addEventField('USER_AGENT_BROWSER_NAME')
addEventField('USER_AGENT_BROWSER_VERSION')
addEventField('USER_AGENT_CPU_ARCHITECTURE')
addEventField('USER_AGENT_DEVICE_MODEL')
addEventField('USER_AGENT_DEVICE_TYPE')
addEventField('USER_AGENT_DEVICE_VENDOR')
addEventField('USER_AGENT_ENGINE_NAME')
addEventField('USER_AGENT_ENGINE_VERSION')
addEventField('USER_AGENT_OS_NAME')
addEventField('USER_AGENT_OS_VERSION')

// TODO - this is actually the ULS-related event fields, not all of them. We need to simplify
// this when we rework the mapping logic between ULS, analytics, and profile field names.
export const EVENT_FIELDS = eventFields

// TODO - after we do the above-mentione refactoring, we'll probably just rename this to EVENT_FIELDS
//
// These are the fields that you must provide on any analytics.page or analytics.track call, otherwise
// the Analytics "debug" plugin will log an error to the console.
export const REQUIRED_EVENT_FIELDS = {
  PAGE_NAME: 'page_name'
}

export const LEAD_ID_COOKIE_MAX_AGE = 30 * 24 * 60 * 60

export const EVENT_TYPES = {
  CONVERSION: 'conversion',
  START: 'start'
}
